import { GetServerSideProps } from 'next'
import { dehydrate } from '@smu-chile/pkg-unimarc-hooks'
import {
  MetaHead,
  ResponsiveContext,
  Home,
  HeaderGiftcardProps,
  FooterGiftcardProps
} from 'components'
import { PropsHome } from 'shared/interfaces/Home'
import { getContentFulComponents } from 'shared/helpers'
import dynamic from 'next/dynamic'
import { GIFTCARDS } from 'shared/utils/contants'

const FooterGiftcard = dynamic<FooterGiftcardProps>(
  import('../components').then((mod) => {
    return mod.FooterGiftcard
  }),
  { ssr: true }
)

const HeaderGiftcard = dynamic<HeaderGiftcardProps>(
  import('../components').then((mod) => {
    return mod.HeaderGiftcard
  }),
  { ssr: true }
)

const Index = ({
  data,
  isMobile,
  salesChannel,
  seoInfo
}: PropsHome): React.ReactElement => {
  return (
    <ResponsiveContext isMobile={isMobile}>
      <>
        <HeaderGiftcard />
        <MetaHead
          contentDescription={seoInfo?.description}
          titleName={seoInfo?.pageTitle}
          type='HOME'
        />
        <Home
          data={data}
          isMobile={isMobile}
          salesChannel={salesChannel}
        />
        <FooterGiftcard />
      </>
    </ResponsiveContext>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const contentOptions = {
    options: {
      content_type: 'home',
      'sys.id[in]': GIFTCARDS.HOME,
      limit: 1,
      include: 2
    }
  }
  const {
    response: data,
    queryClient,
    seoInfo
  } = await getContentFulComponents({ contentOptions })

  return {
    props: {
      data,
      salesChannel: req.cookies.co_sc || null,
      seoInfo,
      dehydratedState: dehydrate(queryClient)
    }
  }
}

export default Index
